<template>
  <div id="app">
    <!-- <contentManageIndex /> -->
    <transition name="router-slid">
      <router-view v-if="!$route.meta.keepAlive && isRouterAlive"></router-view>
    </transition>
    <transition name="router-slid">
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive"></router-view>
      </keep-alive>
    </transition>
    <transition name="router-slid">
      <Iframe v-if="iframeUrl" @iframeFixedHide="iframeUrl = ''"></Iframe>
    </transition>
  </div>
</template>

<script>
// import contentManageIndex from './views/contentManageIndex.vue'

export default {
  name: 'App',
  provide(){
    return{
      reload: this.reload
    }
  },
  components: {
    // contentManageIndex
  },
  data(){
    return {
      iframeUrl:'',
      isRouterAlive:true
    }
  },
  methods:{
    reload(){
      this.isRouterAlive = false
      this.$nextTick(()=>{
        this.isRouterAlive = true
      })
    },
  }
}
</script>

<style>
#app {
}
</style>
