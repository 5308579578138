import Vue from 'vue'
import App from './App.vue'

// 使用vuex
import Vuex from 'vuex'
Vue.use(Vuex)

/* vuex状态管理 */
import store from './vuex/store'

/* mixins */
import mixins from './mixins'
Vue.mixin(mixins)

/* ElementUI */
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

/* ViewUI */
import ViewUI from 'view-design';
import 'view-design/dist/styles/iview.css';
Vue.use(ViewUI);

/* VForm */
import VForm from 'vform-builds'  //引入VForm库
import 'vform-builds/dist/VFormDesigner.css'  //引入VForm样式
Vue.use(VForm)

import SlideVerify from 'vue-monoplasty-slide-verify';
Vue.use(SlideVerify);

import SignCanvas from "sign-canvas";
Vue.use(SignCanvas);

/* 路由 */
import router from './router/index' // 引用路由配置文件

import '@/assets/iconfont/iconfont.css'

Vue.config.productionTip = false

// 引入imgUpload方法
import * as imgUpload from "./assets/utils/imgUpload"
//外部使用
Vue.prototype.$imgUpload = imgUpload

import 'lib-flexible/flexible'
// 屏幕自适应穿透px为rem模板
const baseSize = 16; // 设计稿根字体大小
function setRem() {
  if (
    document.documentElement.clientWidth >= 762 &&
    document.documentElement.clientWidth <= 2040
  ) {
    const scale = document.documentElement.clientWidth / 1920; // 计算缩放比例
    document.documentElement.style.fontSize =
      baseSize * Math.min(scale, 2) + "px"; // 设置根字体大小
  }
  if (
    document.documentElement.clientWidth >= 300 &&
    document.documentElement.clientWidth <= 762
  ) {
    // const scale = (750) / 1920 // 计算缩放比例
    document.documentElement.style.fontSize = 62.5 + "%"; // 设置根字体大小
    // document.documentElement.style.fontSize = (baseSize * Math.min(scale, 2)) + 'px'
  }
}

// setRem();
// window.addEventListener("resize", setRem); // 窗口大小改变时重新计算

import VueAnimateNumber from 'vue-animate-number'
Vue.use(VueAnimateNumber)
import '@/assets/fontFamily/font.css'
new Vue({
  render: h => h(App),
  router,store
}).$mount('#app')
