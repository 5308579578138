import axios from 'axios'
const inviroument = process.env.NODE_ENV
import api from './apiConfig' //真实接口配置
// import store from '../vuex/store';
import qs from 'qs'
import router from '@/router/index'


/**  axios基础配置 */
axios.defaults.timeout = 50000;
axios.defaults.headers['Content-Type'] = 'application/json;charset=UTF-8'

// 请求拦截器
axios.interceptors.request.use(
    config => {
        if (localStorage.aynUserToken) {
            config.headers.common['X-AIYANGNIU-SIGNATURE'] = localStorage.aynUserToken;
        }
        return config;
    },
    err => {
        return Promise.reject(err);
    });

// 响应拦截器
axios.interceptors.response.use(
    response => {
      if (response.data) {
        let code = response.data.code
        switch (code) {
          case 109: // 109 清除token信息并跳转到登录页面
            localStorage.aynUserToken = ''
            vue.$loginVerify()
            break;
          case 110:
            vue.$roleVerify()
            break;
        }
      }
        return response;
    },
    error => {
        if (error.response) {
          let status = error.response.status
          switch (status) {
            case 401: // 109 清除token信息并跳转到登录页面
              localStorage.aynUserToken = ''
              vue.$loginVerify()
              break;
              case 403: // 109 清除token信息并跳转到登录页面
              location.hash = "#/page403"
              break;
          }
        }
        let err = error.response ? (error.response.data || error.response) : (error.message || error)
        return Promise.reject(err)
    }
  );

/**
 * 二次封装axios请求
 */
export default async(type, apiName, url, data, options) => {

    let result = {}
    let path = (apiName != '')? api[apiName] + url:url
    
    //post/put data转form
    if(options && options.form){
        let opt = {headers:{'Content-Type':'application/x-www-form-urlencoded'}}
        data = qs.stringify(data)
        options === {}?options.headers = opt.headers:options = opt
    }

    //axios
    await axios[type](path,data,options).then((res) => {
        result = res.data
    })
    

    return result
}