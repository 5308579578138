/**
 * 引入vuex模块
 */
import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

import login from './modules/login'   //登录注册

export default new Vuex.Store({
  // 组合各个模块
  modules: {
    login
  },
  strict: debug,
})
