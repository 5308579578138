import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/vuex/store.js'  //引用vuex
Vue.use(VueRouter) //调用

// 防止路由重复点击报错
// // push
// const originalPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push (location) {
//   return originalPush.call(this, location).catch(err => err)
// }


/**
 * 引入组件
 */

// 主页
const index = resolve => require(['@/views/index.vue'], resolve) 
//企业介绍
const companyIntroduction = resolve => require(['@/views/aboutus/companyIntroduction.vue'], resolve) 
//社会责任
const socialResponsibility = resolve => require(['@/views/aboutus/socialResponsibility.vue'], resolve) 
//服务承诺
const serviceCommitment = resolve => require(['@/views/aboutus/serviceCommitment.vue'], resolve) 
//党建园地
const buildGarden = resolve => require(['@/views/aboutus/buildGarden.vue'], resolve) 
//资讯中心
const InformationCenter = resolve => require(['@/views/InformationCenter/InformationCenter.vue'], resolve) 
//产品中心
const productCenter = resolve => require(['@/views/productCenter.vue'], resolve) 
//技术服务
const technicalService = resolve => require(['@/views/technicalService.vue'], resolve) 
//联系我们--招贤纳士
const recruitPeople = resolve => require(['@/views/contactUs/recruitPeople.vue'], resolve) 
//联系我们--联系方式
const contactInfomation = resolve => require(['@/views/contactUs/contactInfomation.vue'], resolve) 
//联系我们--意向合作
const cooperation = resolve => require(['@/views/contactUs/cooperation.vue'], resolve) 
//文章详情
const InfoDetail = resolve => require(['@/views/InformationCenter/InfoDetail.vue'], resolve) 
//云养牛首页
const yunIndex = resolve => require(['@/views/yunyangniu/index.vue'], resolve) 
//云养牛--智慧牧场
const smartRanch = resolve => require(['@/views/yunyangniu/smartRanch.vue'], resolve) 
//云养牛--繁育保健
const breedHealth = resolve => require(['@/views/yunyangniu/breedHealth.vue'], resolve) 
//云养牛--饲喂
const feed = resolve => require(['@/views/yunyangniu/feed.vue'], resolve) 
//云养牛--牛舍
const cowshed = resolve => require(['@/views/yunyangniu/cowshed.vue'], resolve) 
//云养牛--奶厅
const dairyParlor = resolve => require(['@/views/yunyangniu/dairyParlor.vue'], resolve) 

let route=[]
/**
 *  配置路由
 */
const routes =  [
  //地址为空时跳转home页面
  {
    path: '/',
    redirect: '/index'
  },
  {
    path: '/index',
    component: index,
    name:'首页',
    meta: {
      loggedIn: true //登录验证
    },
  },
  {
    path: '/companyIntroduction',
    component: companyIntroduction,
    name:'企业介绍',
  },
  {
    path: '/socialResponsibility',
    component: socialResponsibility,
    name:'社会责任',
  },
  {
    path: '/serviceCommitment',
    component: serviceCommitment,
    name:'服务承诺',
  },
  {
    path: '/buildGarden',
    component: buildGarden,
    name:'党建园地',
  },
  {
    path: '/InformationCenter',
    component: InformationCenter,
    name:'资讯中心',
  },
  {
    path: '/productCenter',
    component: productCenter,
    name:'产品中心',
  },
  {
    path: '/technicalService',
    component: technicalService,
    name:'技术服务',
  },
  {
    path: '/recruitPeople',
    component: recruitPeople,
    name:'招贤纳士',
  },
  {
    path: '/contactInfomation',
    component: contactInfomation,
    name:'联系方式',
  },
  {
    path: '/cooperation',
    component: cooperation,
    name:'意向合作',
  },
  {
    path: '/InfoDetail',
    component: InfoDetail,
    name:'文章详情',
  },
  {
    path: '/yunIndex',
    component: yunIndex,
    name:'云养牛首页',
  },
  {
    path: '/smartRanch',
    component: smartRanch,
    name:'智慧牧场管理平台',
  },
  {
    path: '/breedHealth',
    component: breedHealth,
    name:'繁育保健数字化',
  },
  {
    path: '/feed',
    component: feed,
    name:'饲喂数字化',
  },
  {
    path: '/cowshed',
    component: cowshed,
    name:'牛舍数字化',
  },
  {
    path: '/dairyParlor',
    component: dairyParlor,
    name:'奶厅数字化',
  },
  
]


/**
 *  路由对象
 */
const router = new VueRouter({ // 使用配置文件规则
  routes,
})


// 防止手动输入url越权限制
const checkPreMethod = (to,from,next)=>{
  // 涉及多页面跳转提前封装
  let loc = window.location
  let url = ''
  url = loc.protocol + '//' + loc.host + '/views/'    
  // 路由守卫防止前端越权
}


/**
 *  路由钩子
 */

/* 路由跳转前 */
router.beforeEach((to, from, next) => {
  if(!!localStorage.aynUserToken) {  // 本地存储是否有aynUserToken,
    if(store.getters.loginStatus){  //已获取用户信息
      next()
    }else{  //未获取用户信息
      store.dispatch('getUserInfo').then(()=>{
        next()
      }).catch((error) => {
        localStorage.clear()
        window.location.reload()
      });
    }
  }else{  //用户未登录
    next()
  }
})

export default router
