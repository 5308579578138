import res from './axios'


export const GetUserToken = (params) => res('post', 'apiManager', '/manager/login', params, {form: true})
export const GetUserInfo = () => res('get', 'apiManager', '/manager/managerUser',{})

//新增意向合作
export const createShop = (params) => res('post', 'apiWebsite','/portalShop/create',params);
//获取招聘信息列表
export const recruitList = (params) => res('get', 'apiWebsite', '/portalWebsite/recruitList',{params})
//获取模块信息以及数据信息（根据code等级）--不分页
export const resourceList = (params) => res('get', 'apiWebsite', '/portalWebsite/resourceList',{params})
//获取模块信息以及数据信息（根据code等级）--分页
export const resourcePage = (params) => res('get', 'apiWebsite', '/portalWebsite/resourcePage',{params})
//获取文章详情
export const getResource = (params) => res('get', 'apiWebsite', '/portalWebsite/getResource',{params})
//树形结构返回code下模块列表
export const treeCodeList = (params) => res('get', 'apiWebsite', '/portalWebsite/treeCodeList',{params})

//根据条件分页查询课程列表（无校验）
export const getPageListNotAuth = (params) => res('get', 'apiNrhNew', '/course/getPageListNotAuth',{params})
//直播回放列表(无校验)
export const getPlaybackLogsNotAuth = (params) => res('get', 'apiNrhNew', '/course/getPlaybackLogsNotAuth',{params})
//查询课程分类列表
export const getCategoryList = (params) => res('get', 'apiNrhNew', '/course/getCategoryList',{params})
