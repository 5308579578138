/**
 * 自定义过滤器
 */

export default {
  // 时间格式
  dateFormate(val,fmt){
    const dt = new Date(val)
    const y = dt.getFullYear()
    const m = (dt.getMonth() + 1 + '').padStart(2, '0')
    const d = (dt.getDate() + '').padStart(2, '0')
    const hh = (dt.getHours() + '').padStart(2, '0')
    const mm = (dt.getMinutes() + '').padStart(2, '0')
    const ss = (dt.getSeconds() + '').padStart(2, '0')

    if(fmt){
      return `${y}-${m}-${d} ${hh}:${mm}:${ss}`
    }else{
      return `${y}-${m}-${d}`
    }
  },

  toFixed:function(val){
    if(typeof val == 'number'){
      return val.toFixed(2)
    }
  },
  typeValue(val){
    switch (val) {
      case 1:
        return '销售'
      case 2:
        return '采购'
      case 3:
        return '财务'
      case 4:
        return '运营'
      case 5:
        return '市场'
      case 6:
        return '人力'
      case 7:
        return '技术'
    }
  },
  educationValue(val){
    switch (val) {
      case 1:
        return '专科'
      case 2:
        return '本科'
      case 3:
        return '硕士及以上'
    }
  },
  workYearsValue(val){
    switch (val) {
      case 1:
        return '1-3年'
      case 2:
        return '3-5年'
      case 3:
        return '5-10年'
      case 4:
        return '10年以上'
    }
  },
}
