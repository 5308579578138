// 接口配置文件
let env = process.env.NODE_ENV
let api
if (env === 'development') {  //开发环境
  api = {
    apiWebsite: 'http://website.aiyangniu.net',
    apiManager: 'http://manager2.aiyangniu.net',
    apiNrhNew:'http://nrhapi.aiyangniu.net' , //牛人慧（新接口）
  }
} else if (env === 'testing') {
  api = {
    apiWebsite: 'http://website.aiyangniu.net',
    apiManager: 'http://manager2.aiyangniu.net',
    apiNrhNew:'http://nrhapi.aiyangniu.net' , //牛人慧（新接口）
  }
} else { //生产环境
    api = {
      apiWebsite: 'https://website.aiyangniu.cn',
      apiManager: 'https://manager.aiyangniu.cn',
      apiNrhNew:'https://nrhapi.aiyangniu.cn' , //牛人慧（新接口）
  }
}


export default api
